import React from 'react';
import { graphql } from 'gatsby';

import {
	Layout,
	LogoList,
	// ButtonCircle,
	Hero,
	Container,
	Grid,
	Separator,
	NumberedContent,
	Spacer,
} from '../components';

const renderRow = (row) => (
	<React.Fragment>
		<p className="f-large color-black-light">{row.description}</p>
		<Grid align="middle-sm">
			<Grid.Col width="md-auto">
				<LogoList className="margins__double" logos={row.retailers} />
			</Grid.Col>
			{/* <Grid.Col width="md">
				<div
					className="margins__double"
					style={{ paddingLeft: '20px', textAlign: 'center' }}
				>
					<ButtonCircle>Read more</ButtonCircle>
				</div>
			</Grid.Col> */}
		</Grid>
	</React.Fragment>
);

const RetailersPage = ({
	pageContext,
	data: {
		wordpressPage: { acf: translations },
	},
}) => (
	<Layout
		title={translations.seo_title}
		description={translations.seo_description}
		image={translations.seo_cover_image}
		pageContext={pageContext}
	>
		<Hero
			image={translations.retailers_background_image}
			title={translations.retailers_title}
			text={translations.retailers_descriptions}
		/>
		<Container left={236}>
			{translations.retailers_countries.map((row, i) => (
				<React.Fragment key={i}>
					{i !== 0 && <Separator />}
					<Spacer desktop={i === 0 ? 60 : 80} mobile={50} />
					<NumberedContent number={i + 1} title={row.country}>
						{renderRow(row)}
					</NumberedContent>
					<Spacer desktop={70} mobile={30} />
				</React.Fragment>
			))}
		</Container>
		<Spacer desktop={70} mobile={30} />
	</Layout>
);

export default RetailersPage;

export const query = graphql`
	query($id: Int, $lang: String) {
		wordpressPage(
			wordpress_id: { eq: $id }
			wpml_current_locale: { eq: $lang }
		) {
			wpml_current_locale
			acf {
				seo_title
				seo_description
				seo_cover_image
				retailers_title
				retailers_descriptions {
					description
				}
				retailers_background_image
				retailers_countries {
					country
					description
					retailers {
						image
						name
					}
				}
			}
		}
	}
`;
